import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconBackyard = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconwrapcorn_large.jpg';
const RecipePage = () => (
    <Layout metaTitle="Bacon Backyard Corn Recipe"
        metaDescription="There’s a certain savory, smoky flavor we think corn has been missing. Use the  backyard corn recipe with Wright Brand Bacon to amaze your next party!"
        metaKeywords="backyard corn recipe"
    >
        <meta property="og:image" content={imageBaconBackyard} />
        <link rel="image_src" href={imageBaconBackyard} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconBackyard} class='img-responsive' alt='Bacon Wrapped Corn' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Backyard Corn</h1>

                            <div class="recipe-content">
                                <p >We&rsquo;ve always loved the taste of corn, but there&rsquo;s a certain savory, smoky flavor we think it&rsquo;s been missing. We remedy that situation by taking our hand-trimmed bacon and wrapping it around corn cob halves, rolling them in a sweet and spicy chipotle honey glaze. It all comes together for a satisfying summer treat.</p>
                                <p><br />
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        10&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        25&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> slices Wright® Brand Naturally Hickory Smoked Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> chipotle peppers, canned, pureed
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> honey
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> butter, melted
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                4 <span class="uom"></span> corn ears, shucked
                </div>      <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                toothpicks
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat your grill on medium heat.

                </p>
                            <p>
                                2. While the grill warms up, mix chipotle peppers, honey and melted butter in a bowl to make the glaze.

                </p>
                            <p>
                                3. Cut the ears of corn in half. Wrap one slice of Wright® Brand bacon around each corn cob half. Stab the ends with toothpicks to make sure that bacon doesn’t go anywhere.

                </p>
                            <p>
                                4. Spray the grill with non-stick cooking spray. Roll the wrapped corn cob halves in the honey chipotle glaze and place them on the grill.

                </p>
                            <p>
                                5. Turn the corn every 1-2 minutes and occasionally baste with the remaining glaze. It should take about 20-25 minutes for the corn to cook and the bacon to crisp up.

                </p>
                            <p>
                                6. Remove corn from the grill, season with salt to taste, and dig in.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    </Layout>
)

export default RecipePage
